import React, { Component } from 'react';

const initData = {
    heading: "sApp is available for all devices",
    headingText: "sApp is available for all devices, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus.",
    headingTexttwo: "sApp is available for all devices, consectetur adipisicing elit. Vel neque, cumque. Temporibus eligendi veniam, necessitatibus aut id labore nisi quisquam.",
    headingSlug: "* Available on iPhone, iPad and all Android devices",
    playImg: "/img/google-play.png",
    appleImg: "/img/app-store.png",
}

class Download extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section className="section download-area overlay-dark ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-md-push-3">
                        {/* Download Text */}
                        <div className="download-text ">
                            <h2 className="pb-4 pb-sm-0">משך הקורס ושיטת הלימוד</h2>
                            <p>קורס &  NLP Practitioner בהנחיית ילדים ונוער בנוי מ-27 שיעורים של תוכן מקצועי מקיף וממוקד (כל שיעור 5 שעות אקדמאיות), הכולל רקע תיאורטי ומעשי ב-NLP ובהתפתחות ילדים ונוער. כל שבוע לאחר פתיחת השיעור המקוון, ניפגש לשיעור חי בכיתה הוירטואלית באמצעות תוכנת ZOOM.</p>
                            <p>חלקו הראשון של כל שיעור יכלול את כל התוכן העיוני וההסברים על הטכניקות ושיטות העבודה, בהתאם לנושא הנלמד, ובחלקו השני נדגים את הטכניקות הנלמדות ואז תרגול.</p>
                            <p>החלק השני של הק'ורס הוא מפגשי סופרויז'ן: אחת לחודש, במועדים שייקבעו, יתקיים מפגש בכיתה הוירטואלית לצורך סופרויז'ן, במסגרתו משתתפי הקורס יציגו את מקרי ההנחיה שלהם בהתנסות המעשית. המקרים ינותחו לעומקם יחד עם מנחת הקורס ומשתתפים אחרים.</p>
                            <p>סה"כ 169 שעות אקדמיות + 12 שעות סופרויז'ן</p>
                            <h3>דרישות לקבלת התעודה "מנחה NLP בהתמחות ילדים ונוער"</h3>
                            <ul className="check-list">
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>צפייה בשיעורים המקוונים ומענה על מבדק שיועלה לאחר כל שיעור</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>השתתפות פעילה ב-85% מהשיעורים הווירטואליים וביצוע התרגולים</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>מבחן סיום עיוני – 40 שאלות אמריקאיות</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>מבחן סיום מעשי</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>התנסות מעשית וסופרוויז'ן: קיום 12 מפגשי הנחיה עם ילדים והגשת דוחות לבדיקה ומשוב והשתתפות במפגשי הסופרוויז'ן הוירטואליים</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={'list-box media'}>
                                        <span className="icon align-self-center"><i className={'fas fa-check'} /></span>
                                        <span>השפעה חיובית על הסביבה</span>
                                    </div>
                                </li>
                            </ul>
                        <a className="btn sApp-btn scroll" href={'#contact'}>לבדיקת התאמה והצטרפות לקורס לחצו כאן</a>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Download;