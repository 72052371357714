import React, { Component } from 'react';

const initData = {
    preHeading: "Premium",
    preHeadingspan: "Features",
    headingText: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingTexttwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}

const data = [
    {
        image: "fab fa-sketch",
        title: "מקצוע מבוקש",
        content: "בשוק היום חסרים מנחי ילדים ונוער טובים, יש כאן הזדמנות לרכוש מקצוע מבוקש עם הרבה משמעות, סיפוק ויכולת השפעה אמיתית."
    },
    {
        image: "fas fa-utensils",
        title: "קורס מעשי ופרקטי",
        content: "את הקורס מסיימים כאשר בידכם  תוכנית  הנחייה מסודרת לילדים ונוער  – לפי מפגשים, ותעודת מנחה NLP מאסטר פרקטישיונר לילדים ונוער."
    },
    {
        image: "fas fa-dog",
        title: "ליווי אישי וצמוד",
        content: "הליווי של התלמידים בקורס הוא אישי וצמוד על ידי המרצה – עם התנסות מעשית בשטח ומשובים קבועים."
    },
    {
        image: "fas fa-child",
        title: "גישה לילדים",
        content: "במהלך הקורס תקבלו ארגז כלים עצום עיוני ומעשי שיסייע לכם לגשת לילדים, להשפיע עליהם לטובה ואפילו לשנות את חייהם."
    },

    {
        image: "fas fa-hiking",
        title: "מסע פנימי מרתק",
        content: "במהלך הקורס כל אחד עובר תהליך עמוק של התפתחות אישית, במהלך התהליך תבינו את השיטה לעומק ממקום חוויתי."
    },

    {
        image: "fas fa-graduation-cap",
        title: "הכשרה רחבה ומקיפה",
        content: "כל המיומנויות והידע שאתם צריכים על מנת לעבוד עם ילדים ונוער באמצעות כלי ה-NLP."
    },
]

class FeatureSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="features" className="section features-area style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row">
                    <div className="col-12 col-sm-8" style={
                        {
                            marginRight: '16.666667%',
                        }
                    }>
                        {/* Section Heading */}
                        <div className="section-heading">
                        <h2>לא קל להיות ילד בעולם של היום...</h2>
                            <p className="lead">הצורך באנשי מקצוע טובים לטיפול בילדים ובני ונוער בארץ הולך וגובר. אנו מציעים קורס מקוון שנתי, מקיף ועשיר בתוכן פרקטי ומעשי, אשר מועבר על ידי המומחית בתחום, על מנת לתת לכם את כל הכלים הדרושים לטיפול בילדים ובני נוער בשיטת NLP.</p>
                            <p>ילדים ונוער זקוקים למענה שונה ממבוגרים, הם זקוקים להעצמה, הבנה והכלה, בתוך האתגרים הרבים שהם מתמודדים איתם ביום יום.</p>
                            <p>קורס זה יעניק לכם זו הזדמנות מיוחדת לרכישת מקצוע לחיים, ארגז כלים עצום, גישה לילדים וידע מעמיק –  על מנת לסייע לילדים ובני נוער בקשיים רגשיים, חברתיים והתנהגותיים, ולשפר את חוויית הילדות וההתבגרות, ובסופו של דבר להשפיע על המבוגר שיגדלו להיות.</p>
                            <p>החזון שלנו הוא להוציא לשוק מנחי NLP מעולים לילדים ונוער, ולהעביר אותם ההכשרה ברמה אקדמית גבוהה ומקצועית.</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        {this.state.data.map((item, idx) => {
                            return(
                                <div key={`ffd_${idx}`} className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Featured Image */}
                                    <div className="featured-img mb-3">
                                        <i className={item.image} />
                                    </div>
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">{item.title}</h3>
                                        <p>{item.content}</p>
                                    </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <h2 className="text-center"><a href="#contact" className="btn btn-bordered mt-4 scroll">לבדיקת התאמה ולהצטרפות לקורס לחצו כאן</a></h2>
                </div>
            </section>
        );
    }
}

export default FeatureSection;