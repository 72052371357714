import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOneTeamSection";

class Team extends Component {
    state = {
        data: {},
        teamData: [],
        teamIcons: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    teamData: res.data.teamData,
                    teamIcons: res.data.teamIcons
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section team-area team-style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">על המכללה</h2>
                            <p className="d-none d-sm-block mt-4">מכללה ללימודי NLP בהסמכה בינלאומית מטעם בית-הספר העולמי HNLP</p>
                        </div>
                    </div>
                    </div>
                    <div className="row the-content">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8">
                            <div className={'row'}>
                                {window.innerWidth > 768 &&
                                <div className={'col-sm-5'}>
                                    <img src={'img/shirly-3.jpg'} alt />
                                </div>
                                }
                                <div className={'col-sm-7'}>
                                    <p>מכללת ״דרך הערך״ הוקמה ע"י שירלי כראדי, NLP Master Practitioner & Trainer, בעלת קליניקה פרטית וניסיון מעשי עשיר בתחום האימון והטיפול, כמו גם בתחום הניהול ועולם העסקים.</p>
                                    <p>לאחר שנים בהם עסקה בטיפול ואימון אישי, ולאחר שנוכחה לתוצאות המדהימות של השימוש בכלי ה-NLP בהיבטים שונים בחייה ובחיי מטופליה, הקימה את מכללת "דרך הערך" ללימודי NLP, זאת במטרה לתת לכל אדם החפץ בכך הזדמנות ללמוד איך לשפר משמעותית את חייו</p>
                                    <p>במסגרת הלימודים במכללה, מונגשים הכלים החשובים והפרקטיים של ה-NLP, להפקת מירב התועלת בכל תחום בחיים, על מנת לחיות חיים טובים ומספקים יותר.</p>
                                    <p>היום שירלי היא המאמנת הבכירה במכללת "דרך הערך".</p>
                                </div>
                                <div className={'col-sm-12 team-silvi'}>
                                    <h3 className={'silvi-title'}>מובילת הקורס</h3>
                                    <p className={'d-none d-sm-block mt-4'}>הקורס יועבר על ידי סילבי אלישקוב ממכללת דרך הערך</p>
                                    <div>
                                        <img className={'silvi-img'} src={'/img/silvi.jpg'} alt />
                                        <h4 className="team-name">סילבי אלישקוב</h4>
                                        <p>תואר שני בייעוץ עם התמחות בהתפתחות הילד. מרצה ומנחת קבוצות, מאסטר וטריינר  NLP ומטפלת רגשית לילדים, נוער ומבוגרים.  ניסיון של מעל ל-12 שנים בתהליכי העצמה, התפתחות אישית וטיפול רגשי לילדים נוער, ומבוגרים והדרכת הורים.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}

export default Team;