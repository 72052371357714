import React, { Component } from 'react';

class ServiceSection extends Component {
    state = {
        data: {},
        serviceData: [


            {
                "id": 1,
                "iconClass": "fas fa-chalkboard-teacher",
                "text": "16 מפגשים אונליין באמצעות תוכנת Zoom באורך של כ-4 שעות",
            },
            {
                "id": 2,
                "iconClass": "fas fa-book-open",
                "text": "המדריך המלא ל-NLP Practitioner - ספר הדרכה אלקטרוני!",
            },
            {
                "id": 3,
                "iconClass": "fas fa-user-graduate",

                "text": "ליווי מלא של מנטורים מוסמכים לכל אורך הקורס",
            },
            {
                "id": 4,
                "iconClass": "fas fa-chart-line",
                "text": "גישה לאזור התלמידים באתר המכללה עם הקלטות של כל השיעורים",
            },


            {
                "id": 5,
                "iconClass": "far fa-thumbs-up",

                "text": "גישה לקהילות הסגורות שלנו קהילות סגורות בפייסבוק ובוואטסאפ",
            },
        ]
    }
    render() {
        return (
            <section className="section service-area overflow-hidden ptb_100">
                <div className="container">
                    <div className=" justify-content-between">
                        <div className={'service-text'}>
                            {/*
                            <div className={'col-sm-6'}>
                                <img src={'/img/nlp-book.png'} alt="הדמיה של ספר הדרכה - NLP Practitioner - מכללת דרך הערך" />
                            </div>
                            */}
                            <div>
                                <h2>למי הקורס מתאים</h2>
                                <p>בין אם אתם מטפלים ואנשי מקצוע, הורים, מורים, גננות או כל מי שמעוניין בהכשרה מקיפה ומקצועית לעבודה עם ילדים ונוער, בהוספת כלי NLP לארגז הכלים שלכם על מנת שתוכלו לסייע לילדים ונוער באתגרים המלווים אותם, להשפיע עליהם באופן חיובי ולהעצים אותם.</p>
                                {/*
                                    <ul className="service-list">
                                    {this.state.serviceData.map((item, idx) => {
                                        return(
                                            <div key={`so_${idx}`}>
                                                <li className="single-service media py-2">
                                                    <div className="service-icon pr-4">
                                                        <span><i className={item.iconClass} /></span>
                                                    </div>
                                                    <div className="service-text media-body">
                                                        <p>{item.text}</p>
                                                    </div>
                                                </li>
                                            </div>
                                        );
                                    })}
                                </ul>
                                */}
                                <a href="#contact" className="btn btn-bordered mt-4 scroll">לבדיקת התאמה והצטרפות לקורס לחצו כאן</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block">
                            {/* Service Thumb */}
                            <div className="service-thumb mx-auto">
                            <img src={this.state.data.thumbTwo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceSection;