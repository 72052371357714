import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneWorkSection";

class Work extends Component {
    state = {
        data: {},
        workData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    workData: res.data.workData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section work-area bg-overlay overflow-hidden ptb_100">
                <div className="container">
                    <div className="row">
                        <div className={'col-sm-8'}>
                            <h2 className="text-white">מה תלמדו בקורס?</h2>
                            <p><i className="fas fa-chevron-circle-left"></i>דרכים יצירתיות ליצירת קשר של אמון עם ילדים ונוער, איך לדבר איתם כך שיסכימו לשתף פעולה ולחלוק עימכם את עולמם הפנימי</p>
                            <p><i className="fas fa-chevron-circle-left"></i>איך לענות על צורכי ההורים ועל צורכי הילד באופן מיטבי</p>
                            <p><i className="fas fa-chevron-circle-left"></i>מהו האופן בו ילדים חושבים ופועלים, ואיך ניתן להתאים את הכלים של ה-NLP לשפה שלהם</p>
                            <p><i className="fas fa-chevron-circle-left"></i>שילוב אמצעי המחשה בתהליכי NLP , למשל: תחפושות, בובות, גרביים, ציור, פיסול, המחזה, משחק, בלונים, סיפורים וקלפים השלכתיים</p>
                            <p><i className="fas fa-chevron-circle-left"></i>איך לזהות את האתגרים העומדים בפני הילידים, ומהן הדרכים האפקטיביות להגיע לתת המודע שלהם על מנת להתמודד עימם</p>
                            <p><i className="fas fa-chevron-circle-left"></i>איך עובדים עם ילדים על 'קו הזמן' באופן יצירתי ומותאם</p>
                            <p><i className="fas fa-chevron-circle-left"></i>איך ניתן לעצב מחדש מחשבות, אמונות ודימוי עצמי של ילדים להעצמה שלהם והגברת תחושת המסוגלות</p>
                            <p><i className="fas fa-chevron-circle-left"></i>איך מטפלים בבעיות נפוצות בגיל הילדות וההתבגרות: בעיות חברתיות, פחדים, חרדות, מחלות, טראומות ואובדנים</p>
                            <p><i className="fas fa-chevron-circle-left"></i>איך אפשר לתרגם באופן יצירתי תהליכי NLP מוכרים כך שיתאימו לעבודה עם ילדים</p>
                            <p><i className="fas fa-chevron-circle-left"></i>ועוד ...</p>
                            <h3 className={'h2'}><a href={'#contact'} className={'btn sApp-btn scroll'}>לשיחת יעוץ לחצו כאן</a></h3>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;